<template>
  <PageHeader
    image="header_11"
    withMobile
    :title="$lang('title')"
    :breadcrumbs="$lang('breadcr')"
  />
  <!-- section1 -->
  <section class="py-8 bg-blue4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6">
          <h3 class="fw-semibold text-blue2 mb-4">
            {{ $lang("section1.title") }}
          </h3>
          <ContactForm @onSent="$router.push({ name: 'thank_you' })" />
        </div>
      </div>
    </div>
  </section>
  <!-- section2 -->
  <section>
    <ImageContainer fileName="section_header_2" background size="400px" />
  </section>
  <!-- section3 -->
  <section class="pt-8 pb-7 bg-green2">
    <div class="container">
      <h2 class="text-blue2 text-center mb-5">
        {{ $lang("section3.title1") }}
      </h2>

      <div class="row">
        <div class="col-12">
          <h3 class="text-white">{{ $lang("section3.title2") }}</h3>
        </div>
        <div class="col-lg-6">
          <CardAddresses phone="+353 (0) 8783047" />
        </div>
        <div class="col-lg-6">
          <CardAddresses title="Building 39" fileName="campus_dublin_2" phone="+353 (0) 8783047" />
        </div>
      </div>
    </div>
  </section>
  <!-- section4 -->
  <section class="pt-7 pb-10 bg-blue3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="text-white">{{ $lang("section4.title") }}</h3>
        </div>
        <div class="col-lg-6">
          <CardAddresses
            fileName="campus_limerick"
            title="Chapel House"
            street=" "
            neighbour="Chapel Court"
            neighbour2="Limerick"
            eircode="V94 YH6Y"
            phone="+353 (0) 61468571"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import CardAddresses from "@/components/CardAddresses";
import ContactForm from "@/components/ContactForm";

export default {
  components: {
    PageHeader,
    CardAddresses,
    ContactForm,
  },
};
</script>

<style></style>
